<template>
  <v-dialog v-model="dialogoEvaluiacionOperador" max-width="500" v-if="evaluar">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-sheet
          width="fit-content"
          class="mt-2 pa-4 rounded-lg elevation-4"
        >
          <h3>
            ¿Qué te pareció tu vuelo a {{ evaluar.destino }} el
            {{ fechaConMesEscrito(evaluar.fecha) }} ?
          </h3>
          <p>Cuéntanos como fue tu experiencia de viaje</p>
          <br />
          <v-btn
            color="transparent"
            elevation="0"
            :style="{
              textTransform: 'initial',
              color: colors.mainPurple,
              fontWeight: '600',
            }"
            class="pa-0"
            >Califica tu vuelo</v-btn
          >
        </v-sheet>
      </div>
    </template>

    <v-sheet class="evaluacion pa-6">
      <div class="d-flex justify-center">
        <v-rating
          color="warning"
          background-color="#C4C4C4"
          v-model="rating"
          :size="ratingSize"
        />
      </div>
      <v-textarea
        outlined
        dense
        rows="3"
        placeholder="Cuéntanos tu experiencia de viaje"
        no-resize
        :color="colors.mainPurple"
        v-model="comentario"
      >
      </v-textarea>
      <div @click="enviarComentario">
        <Boton
          class="calificarExperiencia__btn"
          texto="Enviar calificación"
          height="47px"
          :deshabilitar="rating === 0"
        />
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import { mapActions, mapState } from "vuex";
export default {
  name: "EvaluarOperador",
  components: {
    Boton,
  },
  props: {
    evaluar: {
      default: false,
    },
  },
  data() {
    return {
      dialogoEvaluiacionOperador: false,
      rating: 0,
      comentario: "",
    };
  },
  computed: {
    ...mapState(["colors"]),
    ...mapState("Auth", ["usuario"]),
    ratingSize() {
      if (this.$vuetify.breakpoint.width >= 960) {
        return 56;
      } else {
        return 30;
      }
    },
    fechaConMesEscrito: () => (fecha) => {
      const separarFecha = fecha.split("-").reverse();

      let mes = separarFecha[1];
      switch (mes) {
        case "01":
          mes = "ENE";
          break;
        case "02":
          mes = "FEB";
          break;
        case "03":
          mes = "MAR";
          break;
        case "04":
          mes = "ABR";
          break;
        case "05":
          mes = "MAY";
          break;
        case "06":
          mes = "JUN";
          break;
        case "07":
          mes = "JUL";
          break;
        case "08":
          mes = "AGO";
          break;
        case "09":
          mes = "SEP";
          break;
        case "10":
          mes = "OCT";
          break;
        case "11":
          mes = "NOV";
          break;
        case "12":
          mes = "DIC";
          break;
      }
      separarFecha[1] = mes;
      const newFecha = separarFecha.join("-").slice(0, 6);
      return newFecha;
    },
  },
  methods: {
    ...mapActions("Comentarios", ["evaluarOperador"]),
    async enviarComentario() {
      if (this.rating != 0) {
        let comentario = {
          user: this.usuario.displayName,
          uidUser: this.usuario.uid,
          rating: this.rating,
          comentario: this.comentario,
          date: new Date(),
          compra: this.evaluar,
          califica: "Operador",
          operador: this.evaluar.operador,
        };
        const evaluacion = await this.evaluarOperador(comentario);

        evaluacion ? (this.dialogoEvaluiacionOperador = false) : false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.evaluacion {
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
}
</style>