<template>
  <main class="miPerfil" id="MiPerfil" v-if="usuario && vuelos && experiencias">
    <div>
      <!-- botones mobile -->
      <v-container class="d-flex d-md-none">
        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            
            <EditarMisDatos>
              <Boton :texto="lenguages[idioma].miPerfil.editarMisDatos" width="100%" />
            </EditarMisDatos>
          </v-col>
          <v-col cols="6" class="pl-1">
            <div @click="logout">
              <Boton :texto="lenguages[idioma].miPerfil.cerrarSesion" width="100%" color="red" />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- header -->
      <header class="miPerfil__header d-flex">
        <v-container class="mx-auto d-flex align-center justify-space-between">
          <!-- titulo -->
          <div>
            <h2>{{ lenguages[idioma].miPerfil.misProximasExperiencias }}</h2>
            <h4>{{ lenguages[idioma].miPerfil.visualiza }}</h4>
          </div>

          <!-- Editar datos -->
          <div class="align-self-start">
            <v-row no-gutters class="d-none d-md-flex">
              <v-col class="mr-1">
                <EditarMisDatos>
                  <Boton :texto="lenguages[idioma].miPerfil.editarMisDatos" width="100%" />
                </EditarMisDatos>
              </v-col>
              <v-col class="ml-1">
                <div @click="logout">
                  <Boton :texto="lenguages[idioma].miPerfil.cerrarSesion" width="100%" color="red" />
                </div>
              </v-col>
            </v-row>
            <!-- Card para calificar -->
            <div class="d-none d-md-block" v-if="vuelosEvaluable">
              <EvaluarOperador :evaluar="vuelosEvaluable" />
            </div>
          </div>
        </v-container>
      </header>
      <div class="d-md-none" v-if="vuelosEvaluable">
        <EvaluarOperador :evaluar="vuelosEvaluable" />
      </div>
      <!-- cards vuelos -->
      <v-container class="miPerfil__cards mx-auto">
        <!-- <v-subheader>Vuelos</v-subheader> -->

        <v-row
          v-if="misProximosVuelos.length >= 1"
          no-gutters
          class="miPerfil__vuelos"
        >
          <v-col
            cols="12"
            md="4"
            xl="3"
            class="pa-2"
            v-for="(vuelo, i) in misProximosVuelos"
            :key="i"
          >
            <router-link :to="`/mis-viajes/${vuelo.infoCompra.id}/${vuelo.id}`">
              <CardProximoVuelo :infoVuelo="getVueloById(vuelo.id)" />
            </router-link>

            <v-row>
              <v-col cols="4">
                <Reagendar
                  :origen="vuelo.data.origen"
                  :destino="vuelo.data.destino"
                  :fechaVuelo="vuelo.data.fecha"
                  :infoCompra="vuelo.infoCompra"
                  :precio="vuelo.data.precio"
                  :aeronave="vuelo.data.aeronave"
                  :idVueloAnterior="vuelo.id"
                  :botonDeshabilitado="
                    !deshabilitarCheckIn(
                      `${vuelo.data.fecha} ${vuelo.data.horaDespegue}`
                    )
                  "
                  v-if="
                    deshabilitarCheckIn(
                      `${vuelo.data.fecha} ${vuelo.data.horaDespegue}`
                    )
                  "
                >
                  <Boton
                    :colorTexto="colors.mainPurple"
                    color="#fff"
                    texto="Reagendar"
                    width="100%"
                    :deshabilitar="
                      !deshabilitarCheckIn(
                        `${vuelo.data.fecha} ${vuelo.data.horaDespegue}`
                      )
                    "
                  />
                </Reagendar>

                <Boton
                  :colorTexto="colors.mainPurple"
                  color="#fff"
                  texto="Reagendar"
                  width="100%"
                  :deshabilitar="true"
                  v-else
                />
              </v-col>
              <v-col cols="8">
                <Boton
                  texto="Check-in realizado"
                  width="100%"
                  :deshabilitar="true"
                  v-if="vuelo.checkInDone"
                />
                <router-link
                  v-else
                  :to="
                    deshabilitarCheckIn(
                      `${vuelo.data.fecha} ${vuelo.data.horaDespegue}`
                    )
                      ? '/mi-perfil'
                      : `/check-in/${vuelo.infoCompra.id}/${vuelo.id}`
                  "
                >
                  <Boton
                    texto="web check-in"
                    width="100%"
                    :deshabilitar="
                      deshabilitarCheckIn(
                        `${vuelo.data.fecha} ${vuelo.data.horaDespegue}`
                      )
                    "
                  />
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- no hay vuelos -->
        <!-- <div v-else class="py-10">
          <h2 class="pb-2">No tienes vuelos próximos</h2>
          <router-link :to="{ name: 'Vuelos' }">
            <Boton
              texto="Ver todos los vuelos"
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </div> -->
        <!-- cards experiencias -->
        <v-divider />
        <!-- <v-subheader>Experiencias</v-subheader> -->
        <v-row
          v-if="misProximasExperiencias.length >= 1"
          no-gutters
          class="miPerfil__experiencias"
        >
          <v-col
            cols="12"
            md="4"
            lg="3"
            xl="2"
            class="pa-2 d-flex justify-center"
            v-for="(experiencia, i) in misProximasExperiencias"
            :key="i"
          >
            <div>
              <router-link
                :to="
                  `/mis-viajes/${experiencia.infoCompra.id}/${experiencia.id}`
                "
              >
                <CardExperiencias
                  :infoExperiencia="getExperienciaById(experiencia.id)"
                  class="ma-auto"
                />
              </router-link>
              <v-row v-if="!experiencia.data.reserva">
                <v-col>
                  <Reagendar
                    :origen="experiencia.data.origen"
                    :destino="experiencia.data.destino"
                    :fechaVuelo="experiencia.data.fecha"
                    :infoCompra="experiencia.infoCompra"
                    :precio="+experiencia.data.precio"
                    :aeronave="experiencia.data.aeronave"
                    :idVueloAnterior="experiencia.id"
                  >
                    <Boton
                      :colorTexto="colors.mainPurple"
                      color="#fff"
                      texto="Reagendar"
                      width="100%"
                      :deshabilitar="
                        !deshabilitarCheckIn(
                          `${experiencia.data.fecha} ${experiencia.data.horaDespegue}`
                        )
                      "
                    />
                  </Reagendar>
                </v-col>
                <v-col>
                  <Boton
                    texto="Check-in realizado"
                    width="100%"
                    :deshabilitar="true"
                    v-if="experiencia.checkInDone"
                  />
                  <router-link
                    v-else
                    :to="
                      deshabilitarCheckIn(
                        `${experiencia.data.fecha} ${experiencia.data.horaDespegue}`
                      )
                        ? '/mi-perfil'
                        : `/check-in/${experiencia.infoCompra.id}/${experiencia.id}`
                    "
                  >
                    <Boton
                      texto="web check-in"
                      width="100%"
                      :deshabilitar="
                        deshabilitarCheckIn(
                          `${experiencia.data.fecha} ${experiencia.data.horaDespegue}`
                        )
                      "
                    />
                  </router-link>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <!-- no hay experiencias -->
        <div v-else class="py-10">
          <h2 class="pb-2">{{lenguages[idioma].miPerfil.noTienesExperienciasProximas}}</h2>
          <router-link :to="{ name: 'Experiencias' }">
            <Boton
              :texto="lenguages[idioma].miPerfil.verTodasLasExperiencias"
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
            />
          </router-link>
        </div>
      </v-container>

      <!-- Viajes Pasados -->

      <v-container
        class="mx-auto"
        v-if="
          vuelosYExperienciasPasadas && vuelosYExperienciasPasadas.length > 0
        "
      >
        <h2>{{lenguages[idioma].miPerfil.misViajesAnteriores || 'Mis viajes anteriores' }}</h2>
        <v-data-table
          :headers="headers"
          :items="vuelosYExperienciasPasadas"
          :items-per-page="5"
          sort-by="fecha"
          dense
          locale="es"
          class="rounded-lg elevation-5"
        >
        </v-data-table>
      </v-container>

      <!-- sugerencias -->
      <section class="miPerfil__sugerencias ">
        <div class="miPerfil__sugerencias__header d-flex align-center">
          <v-container class="mx-auto">
            <h2>{{lenguages[idioma].miPerfil.tienesEnMente}}</h2>
            <h4>{{lenguages[idioma].miPerfil.teRecomendamos}}</h4>
          </v-container>
        </div>
        <v-container class="mx-auto">
          <v-row no-gutters class="miPerfil__sugerencias__cards">
            <v-col
              cols="12"
              md="3"
              xl="2"
              class="pa-2"
              v-for="(experiencia, i) in sugerenciasExperiencias"
              :key="i"
            >
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias :infoExperiencia="experiencia" />
              </router-link>
            </v-col>
          </v-row>
          <Boton
            :texto="lenguages[idioma].miPerfil.verTodasLasExperiencias"
            :icono="icons.green.arrowToRight"
            borderRadius="25px"
            height="55px"
            class="mt-5"
          />
        </v-container>
      </section>

      <!-- Favoritos -->
      <section
        class="miPerfil__sugerencias pb-3 d-md-block"
        v-if="experienciasFavoritas.length"
      >
        <div class="miPerfil__sugerencias__header d-flex align-center">
          <v-container class="mx-auto">
            <h2>
              {{lenguages[idioma].miPerfil.misExperienciasFavoritas}}</h2>
          </v-container>
        </div>
        <v-container class="mx-auto">
          <v-row no-gutters class="miPerfil__sugerencias__cards">
            <v-col
              cols="12"
              md="3"
              xl="2"
              class="pa-2"
              v-for="(experiencia, i) in experienciasFavoritas"
              :key="i"
            >
              <router-link
                :to="{
                  name: 'Detalle',
                  params: { tipoDeViaje: 'Experiencias', id: experiencia.id },
                }"
              >
                <CardExperiencias :infoExperiencia="experiencia" />
              </router-link>
            </v-col>
          </v-row>
          <!-- <router-link to="/experiencias">
            <Boton
              :texto="lenguages[idioma].miPerfil.verTodasLasExperiencias"
              :icono="icons.green.arrowToRight"
              borderRadius="25px"
              height="55px"
              class="mt-5"
            />
          </router-link> -->
        </v-container>
      </section>
    </div>
    <footer class="miPerfil__footer d-none d-md-block"></footer>
  </main>
</template>

<script>
import CardProximoVuelo from "@/components/Cards/CardProximoVuelo";
import CardExperiencias from "@/components/Cards/CardExperiencias";
import EditarMisDatos from "@/components/PopUps/EditarMisDatos";
import Reagendar from "@/components/PopUps/Reagendar";
import EvaluarOperador from "@/components/Feedback/EvaluarOperador";
import Boton from "@/components/Botones/Boton";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
export default {
  name: "MiPerfil",
  components: {
    CardProximoVuelo,
    CardExperiencias,
    Boton,
    EditarMisDatos,
    EvaluarOperador,
    Reagendar,
  },
  data() {
    return {
      headers: [
        { text: "Id", value: "id", align: "start", divider: true },
        { text: "Fecha", value: "time", divider: true },
        { text: "Destino", value: "destino", divider: true },
        { text: "Origen", value: "origen", divider: true },
        { text: "Precio por persona ($ clp)", value: "precio", divider: true },
        { text: "Tipo", value: "tipoDeViaje", divider: true },
        { text: "Estado", value: "status", divider: true },
      ],
      vueloAEvaluar: null,
    };
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState(["colors", "icons"]),
    ...mapState("Auth", ["usuario"]),
    ...mapState("Vuelos", ["vuelos"]),
    ...mapGetters("Experiencias", ["getExperienciasAMostrar"]),
    experiencias() {
      return this.getExperienciasAMostrar;
    },
    ...mapState("Comentarios", ["comentarios"]),
    ...mapState("Operadores", ["operadores"]),
    ...mapGetters("Vuelos", ["getVueloById"]),
    ...mapGetters("Experiencias", [
      "proximasExperiencias",
      "getExperienciaById",
      "experienciasFavoritas",
    ]),
    ...mapGetters("Operadores", ["comentariosOfOperadoresById"]),
    comentariosByThisUser() {
      const ordenarPorFecha = (a, b) =>
        new Date(a.fecha) > new Date(b.fecha) ? 1 : -1;
      const idsVuelos_Y_O_Experiencias = this.vuelosYExperienciasPasadas
        .sort(ordenarPorFecha)
        .filter((ve) => ve.status == "Realizado")
        .map((ve) => ve.id);
      if (idsVuelos_Y_O_Experiencias.length == 0) return false;

      const uid = this.usuario.uid;
      const idsComentados = this.comentariosOfOperadoresById({
        idsVuelos_Y_O_Experiencias,
        uid,
      });

      const ids_De_Vuelos_O_Experiencias_SinComentarios = this.vuelosYExperienciasPasadas.filter(
        (ve) =>
          !idsComentados.includes(ve.id) &&
          ve.status.toLowerCase() === "realizado"
      );
      return ids_De_Vuelos_O_Experiencias_SinComentarios;
    },
    misVuelos() {
      const misVuelos = this.usuario.misVuelos;
      let vuelos = [];
      misVuelos.map((compra) => {
        compra.details.ida.infoCompra = compra.details.info;
        compra.details.ida.infoCompra.id = compra.id;

        if (compra.details.vuelta) {
          compra.details.vuelta.infoCompra = compra.details.info;
          compra.details.vuelta.infoCompra.id = compra.id;
        }

        return compra;
      });
      misVuelos.forEach((compra) => {
        vuelos.push(compra.details.ida);
        // vuelos.push(this.getVueloById(compra.details.ida.id));

        if (compra.details.vuelta) {
          vuelos.push(compra.details.vuelta);
          // vuelos.push(this.getVueloById(compra.details.vuelta.id));
        }
      });

      return vuelos || [];
    },
    misExperiencias() {
      const misExperiencias = this.usuario.misExperiencias;
      let experiencias = [];

      misExperiencias.map((compra) => {
        compra.details.ida.infoCompra = compra.details.info;
        compra.details.ida.infoCompra.id = compra.id;
        return compra;
      });

      misExperiencias.forEach((compra) => {
        experiencias.push(compra.details.ida);
        // experiencias.push(this.getExperienciaById(compra.details.ida.id));

        if (compra.details.vuelta) {
          experiencias.push(compra.details.vuelta);
          // experiencias.push(this.getExperienciaById(compra.details.vuelta.id));
        }
      });

      return experiencias || [];
    },
    misProximosVuelos() {
      let filtrados = this.misVuelos.filter((v) => {
        console.log(v);
        return (
          moment(`${v.data.fecha} ${v.data.horaDespegue}`).isSameOrAfter(
            moment()
          ) &&
          v.data.status != "Cancelado" &&
          v.data.status != "Realizado" &&
          v.data.status != "Reagendado"
        );
      });

      return filtrados.sort(this.ordenarPorFecha).slice(0, 6);
    },
    misProximasExperiencias() {
      let filtrados = this.misExperiencias.filter((v) => {
        return (
          moment(`${v.data.fecha} ${v.data.horaDespegue}`).isSameOrAfter(
            moment()
          ) &&
          v.data.status != "Cancelado" &&
          v.data.status != "Realizado" &&
          v.data.status != "Reagendado"
        );
      });
      const reservadas = this.experienciasReservadas;
      const mix = filtrados
        .concat(reservadas)
        .sort(this.ordenarPorFecha)
        .slice(0, 4);
      return mix;
    },
    experienciasReservadas() {
      return this.misExperiencias.filter((e) => e.data.reserva);
    },
    vuelosReservados() {
      return this.misVuelos.filter((v) => v.data.reserva);
    },
    sugerenciasExperiencias() {
      //Arreglar sugerencias
      let proximasExperiencias = this.proximasExperiencias;
      let sugerencias;

      // this.misExperiencias.forEach((miExp) => {
      //   sugerencias = proximasExperiencias.filter((exp) => {
      //     return miExp.id != exp.id;
      //   });
      // });
      // return this.misExperiencias.length >= 1
      //   ? sugerencias.slice(0, 4)
      //   : proximasExperiencias.slice(0, 4);

      return proximasExperiencias.slice(0, 4);
    },
    vuelosPasados() {
      let vuelos = this.misVuelos.map((v) => (v = this.getVueloById(v.id)));
      let addTimeAndId = vuelos
        .map((v) => {
          v.data.tipoDeViaje = "Vuelo";
          v.data.time = `${v.data.fecha} ${v.data.horaDespegue}`;
          v.data.id = v.id;
          return v.data;
        })
        .filter((v) => {
          return moment(v.time).isBefore(moment());
        });

      return addTimeAndId;
    },
    experienciasPasadas() {
      let experiencias = this.misExperiencias.map(
        (e) => (e = this.getExperienciaById(e.id))
      );

      if (!experiencias.every(Boolean)) return [];
      let newExp = experiencias
        .map((v) => {
          v.data.tipoDeViaje = "Experiencia";
          v.data.time = `${v.data.fecha} ${v.data.horaDespegue}`;
          v.data.id = v.id;
          return v.data;
        })
        .filter((v) => {
          return moment(v.time).isBefore(moment());
        });

      return newExp;
    },
    vuelosYExperienciasPasadas() {
      return this.vuelosPasados.concat(this.experienciasPasadas);
    },
    vuelosEvaluable() {
      return this.comentariosByThisUser[0];
    },
  },
  methods: {
    ...mapActions("Auth", ["logout"]),
    ordenarPorFecha(a, b) {
      if (a.data.fecha > b.data.fecha) {
        return 1;
      }
      if (a.data.fecha < b.data.fecha) {
        return -1;
      }
      return 0;
    },
    deshabilitarCheckIn(fechaYHora) {
      let fechaDosDiasAntes = moment(fechaYHora).subtract(2, "d");

      if (fechaDosDiasAntes.isSameOrAfter(moment())) {
        return true;
      } else {
        return false;
      }
    },
  },
  title() {
    return `Mi perfil - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#MiPerfil,
.miPerfil {
  background: #eee;
  padding-top: 100px;
  padding-bottom: 105px;
  &__header {
    background: #e2e2e2;
    height: 66px;
    h2 {
      font-size: 23px;
    }
    h4 {
      font-size: 13px;
    }
  }
  h2 {
    color: #676767;
  }
}

@media (min-width: 960px) {
  #MiPerfil,
  .miPerfil {
    padding-bottom: 0;
    h2 {
      font-size: 30px;
    }
    h4 {
      font-size: 24px;
    }
    &__header {
      height: 190px;
    }
    &__sugerencias__header {
      color: #676767;
    }
    &__footer {
      height: 184px;
      background: $main-purple;
    }
  }
}
</style>
