<template>
  <v-dialog v-model="reagendar" persistent max-width="500" class="dialog">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot> </slot>
      </div>
    </template>

    <!-- SIN VUELOS DISPONIBLES  -->
    <div v-if="fechasPermitidas.length === 0" class="form pa-2 text-center">
      <h2>
        No hay {{ infoCompra.tipoDeViaje.toLocaleLowerCase() }} disponibles para
        reagendar
      </h2>
      <br />
      <h3 class="py-5">
        Por favor contáctanos para poder ofrecer una solución al respecto.
      </h3>

      <v-row>
        <v-col>
          <div @click="cancelar">
            <Boton texto="Cancelar" color="red" width="100%" />
          </div>
        </v-col>
        <v-col>
          <a
            :href="`https://wa.me/${contacto.whatsapp}?text=${mensajeWhatsapp}`"
            target="_blank"
          >
            <div @click="cancelar">
              <Boton texto="Contactar" width="100%" />
            </div>
          </a>
        </v-col>
      </v-row>
    </div>

    <!-- si hay disponibilidad para reagendar -->
    <div v-else class="form pa-2">
      <!-- alerta de cobro -->
      <div v-if="aceptoCondiciones === false">
        <h3>
          Esta acción tiene un cargo del 30% adicional al valor de tu pasaje.
        </h3>
        <br />
        <p>
          El reagendamiento no admite descuentos y se tomará como base el valor
          de tu vuelo original.
        </p>
        <p>
          Si tu nuevo vuelo tiene un valor superior, estás aceptando el pago de
          la diferencia, adicional al cargo por reagendamiento. Una vez escogido
          tu nuevo vuelo, pasarás a la plataforma de pago.
        </p>

        <v-row>
          <v-col>
            <div @click="cancelar">
              <Boton texto="Cancelar" color="red" width="100%" />
            </div>
          </v-col>
          <v-col @click="aceptoCondiciones = true">
            <Boton texto="Ok, estoy de acuerdo" width="100%" />
          </v-col>
        </v-row>
      </div>

      <!-- Date Picker -->
      <div v-if="aceptoCondiciones && !fechaElegida && !mostrarDetallesFinales">
        <h3>Motivo de reagendamiento</h3>
        <v-textarea
          v-model="motivo"
          placeholder="Explique el motivo del reagendamiento"
          rows="2"
          outlined
          :color="colors.mainPurple"
        />
        <h3>Selecciona una de las fechas disponibles para reagendar</h3>
        <div class="d-flex align-center justify-center">
          <v-date-picker
            v-model="date"
            :color="colors.mainPurple"
            locale="ES-es"
            no-title
            :allowedDates="allowedDates"
            full-width
          ></v-date-picker>
        </div>
        <v-row>
          <v-col>
            <div @click="cancelar">
              <Boton texto="Cancelar" color="red" width="100%" />
            </div>
          </v-col>
          <v-col>
            <!-- <Boton
              texto="Reagendar vuelo"
              width="100%"
              :deshabilitar="date === fechaVuelo || motivo.trim() === ''"
            /> -->

            <v-btn
              v-if="date === fechaVuelo"
              @click="fechaElegida = true"
              class="rounded-lg"
              :color="colors.mainPurple"
              disabled
              width="100%"
            >
              Reagendar
            </v-btn>
            <v-btn
              v-else
              @click="fechaElegida = true"
              class="rounded-lg"
              :color="colors.mainPurple"
              width="100%"
              dark
            >
              Reagendar
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <!-- posibilidades de vuelo -->
      <div v-if="fechaElegida && !mostrarDetallesFinales">
        <div v-if="vuelosEnFechaEscogida.length === 1">
          <div>
            <h3>
              Vuelo<span v-if="vuelosEnFechaEscogida.length > 1">s</span>
              disponible<span v-if="vuelosEnFechaEscogida.length > 1">s</span>
            </h3>
            <div v-for="(vuelo, i) of vuelosEnFechaEscogida" :key="i">
              <div v-if="infoCompra.tipoDeViaje === 'Vuelos'">
                <input
                  type="radio"
                  :id="vuelo.id"
                  name="vueloIda"
                  :value="vuelo.id"
                  v-model="vueloSeleccionado"
                  checked
                />
                <label :for="vuelo.id">
                  <CardVuelo
                    :infoVuelo="vuelo"
                    :class="{ checkedRadio: vueloSeleccionado === vuelo.id }"
                  />
                </label>
              </div>
              <div v-else-if="infoCompra.tipoDeViaje === 'Experiencias'">
                <input
                  type="radio"
                  :id="vuelo.id"
                  name="vueloIda"
                  :value="vuelo.id"
                  v-model="vueloSeleccionado"
                  checked
                />
                <label :for="vuelo.id">
                  <CardExperiencias
                    :infoExperiencia="vuelo"
                    :class="{ checkedRadio: vueloSeleccionado === vuelo.id }"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <v-row>
          <v-col>
            <div @click="cancelar">
              <Boton texto="Cancelar" color="red" width="100%" />
            </div>
          </v-col>
          <v-col>
            <v-btn
              v-if="!vueloSeleccionado"
              @click="fechaElegida = true"
              class="rounded-lg"
              :color="colors.mainPurple"
              disabled
              width="100%"
            >
              Selecciona vuelo
            </v-btn>
            <v-btn
              v-else
              @click="confirmarEleccionDeVuelo"
              class="rounded-lg"
              :color="colors.mainPurple"
              width="100%"
              dark
            >
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <!-- detalles vuelo -->
      <div v-if="mostrarDetallesFinales && vueloSeleccionado">
        <p>Para finalizar tu reagendamiento debes pagar el monto total de:</p>
        <h2>${{ precioEnMiles(montoAPagar) }}</h2>
        <p>correspondientes a:</p>
        <p>Cargo por reagendamiento: ${{ precioEnMiles(valorMulta) }}</p>
        <p v-if="diferenciaMayorACero">
          Diferencia entre valores de vuelos: ${{
            precioEnMiles(diferenciaEnDinero)
          }}
          por pasajero
        </p>
        <v-row>
          <v-col>
            <div @click="cancelar">
              <Boton texto="Cancelar" color="red" width="100%" />
            </div>
          </v-col>
          <v-col @click="irAlPago">
            <Boton
              :texto="
                vueloSeleccionado ? 'Reagendar vuelo' : 'Selecciona vuelo'
              "
              width="100%"
              :deshabilitar="!vueloSeleccionado"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import axios from "axios";
import utils from "@/helpers/utils";
const { formatString } = utils;
import firebase from "firebase";
import CardVuelo from "@/components/Cards/CardProximoVuelo";
import CardExperiencias from "@/components/Cards/CardExperiencias";
import Boton from "@/components/Botones/Boton";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import jwt from "jsonwebtoken";
import moment from "moment";
export default {
  name: "Reagendar",
  components: { Boton, CardVuelo, CardExperiencias },
  props: {
    fechaVuelo: {
      required: true,
      type: String,
    },
    origen: {
      required: true,
      type: String,
    },
    destino: {
      required: true,
      type: String,
    },
    aeronave: {
      required: true,
      type: String,
    },
    infoCompra: {
      required: true,
      type: Object,
    },
    precio: {
      required: true,
      type: Number,
    },
    botonDeshabilitado: {
      type: Boolean,
    },
    idVueloAnterior: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reagendar: false,
      aceptoCondiciones: false,
      fechaElegida: false,
      vueloSeleccionado: null,
      mostrarDetallesFinales: false,
      date: null,
      motivo: "",
      medioDePago: "flow",
      documentoVueloSeleccionado: null,
    };
  },
  computed: {
    ...mapState(["colors", "contacto"]),
    ...mapState("Auth", ["usuario"]),
    ...mapGetters("Vuelos", ["proximosVuelos", "getVueloById"]),
    ...mapGetters("Experiencias", [
      "proximasExperiencias",
      "getExperienciaById",
    ]),
    vuelosDisponiblesParaReagendar() {
      const {
        fechaVuelo,
        precio,
        aeronave,
        destino,
        origen,
        infoCompra,
      } = this;
      const { tipoDeViaje, pasajeros } = infoCompra;

      const condiciones = {
        fechaVuelo,
        precio,
        aeronave,
        destino,
        origen,
        tipoDeViaje,
        pasajeros,
      };

      let vuelos = [];

      const condicionesParaReagendar = (v) => {
        let { fechaVuelo, aeronave, destino, origen, pasajeros } = condiciones;
        return (
          formatString(v.data.origen) === formatString(origen) &&
          formatString(v.data.destino) === formatString(destino) &&
          v.data.capacidad - v.data.ventas >= pasajeros &&
          v.data.fecha != fechaVuelo
          // v.data.aeronave === aeronave
        );
      };

      if (tipoDeViaje === "Vuelos") {
        vuelos = this.proximosVuelos.filter(condicionesParaReagendar);
      }
      if (tipoDeViaje === "Experiencias") {
        vuelos = this.proximasExperiencias.filter(condicionesParaReagendar);
      }

      return vuelos;
    },
    fechasPermitidas() {
      let fechas = this.vuelosDisponiblesParaReagendar.map((v) => v.data.fecha);
      return fechas;
    },
    mensajeWhatsapp() {
      return `Hola , soy ${this.usuario.displayName} y quisiera reagendar un viaje ${this.origen}-${this.destino} con fecha ${this.fechaVuelo}. En el sitio no habían fechas disponibles.`;
    },
    vuelosEnFechaEscogida() {
      return this.vuelosDisponiblesParaReagendar.filter((v) => {
        return v.data.fecha === this.date;
      });
    },
    esVuelo() {
      let esVuelo = this.getVueloById(this.vuelosEnFechaEscogida[0]);
      let esExperiencia = this.getExperienciaById(
        this.vuelosEnFechaEscogida[0]
      );

      return esVuelo && !esExperiencia ? true : false;
    },
    diferenciaEnDinero() {
      return this.documentoVueloSeleccionado.ida.data.precio - this.precio;
    },
    diferenciaMayorACero() {
      return this.diferenciaEnDinero > 0;
    },
    valorMulta() {
      return Math.round(this.precio * 0.3);
    },
    montoAPagar() {
      return this.diferenciaMayorACero
        ? (this.valorMulta + this.diferenciaEnDinero) *
            this.infoCompra.pasajeros
        : this.valorMulta * this.infoCompra.pasajeros;
    },
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  methods: {
    ...mapMutations("Ventas", ["VUELOS_ELEGIDOS"]),
    ...mapMutations("Auth", ["LOADING_ON", "LOADING_OFF"]),
    ...mapActions("Ventas", ["getVentaById"]),
    allowedDates(val) {
      if (this.fechasPermitidas.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    cancelar() {
      this.reagendar = false;
      this.aceptoCondiciones = false;
      this.fechaElegida = false;
      this.vueloSeleccionado = null;
      this.mostrarDetallesFinales = false;
    },
    async confirmarEleccionDeVuelo() {
      const getVueloEscogido = this.vuelosEnFechaEscogida.find(
        (v) => v.id === this.vueloSeleccionado
      );

      const vueloSeleccionadoYData = {
        ida: getVueloEscogido,
        vuelta: null,
        info: {
          tipoDeVuelo: "Solo ida",
          tipoDeViaje: this.infoCompra.tipoDeViaje,
          pasajeros: this.infoCompra.pasajeros,
        },
      };

      this.documentoVueloSeleccionado = vueloSeleccionadoYData;
      this.mostrarDetallesFinales = true;
    },
    async irAlPago() {
      try {
        let documentoCompra = await firebase
          .firestore()
          .collection("ventas")
          .doc(this.infoCompra.id)
          .get();

        //Nuevo documento de compra
        let datosVenta = {
          details: this.documentoVueloSeleccionado,
          userData: {
            id: this.usuario.uid,
            email: this.usuario.email,
            displayName: this.usuario.displayName,
          },
          date: moment().format("YYYY-MM-DD HH:mm"),
        };
        datosVenta.details.info.reagendado = true;
        datosVenta.details.info.motivoReagendar = this.motivo;
        datosVenta.details.listaPasajeros = documentoCompra.data().details.listaPasajeros;
        datosVenta.details.medioPago = "webpay";
        datosVenta.details.total = this.montoAPagar;
        datosVenta.details.ida.checkInDone = false;
        datosVenta.subject = `Compra de ${datosVenta.details.info.tipoDeViaje
          .toLowerCase()
          .slice(0, -1)}`;
        let info = {
          datosVenta,
          infoCompraAnterior: {
            idCompra: this.infoCompra.id,
            idVueloAnterior: this.idVueloAnterior,
          },
        };

        const infoToken = jwt.sign(info, "hummingbird");
        if (this.medioDePago == "flow") {
          try {
            this.LOADING_ON();
            const { data } = await axios.post(
              // `http://localhost:5001/hummingbird-453db/us-central1/flow/apiFlow/reagendamiento/${this.montoAPagar}?datosVenta=${infoToken}`
              `https://us-central1-hummingbird-453db.cloudfunctions.net/flow/apiFlow/reagendamiento/${this.montoAPagar}?datosVenta=${infoToken}`
            );
            const { redirect } = data;
            window.location = redirect;
          } catch (e) {
            this.$toast.error("Algo salió mal ..." + e.message, {
              position: "top",
              duration: 3000,
            });
            this.LOADING_OFF();
          }
        } else if (this.medioDePago == "webpay") {
          window.location = `https://us-central1-hummingbird-453db.cloudfunctions.net/tbk/pago/${this.montoAPagar}?datosVenta=${infoToken}`;
          // window.location = `http://localhost:5001/hummingbird-453db/us-central1/tbk/reagendamiento/${this.montoAPagar}?datosVenta=${infoToken}`;
        }
      } catch (error) {
        alert(error.message);
      }
    },
  },
  mounted() {
    this.date = this.fechaVuelo;
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.form {
  background: $main-white;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15) !important;
  border-radius: 20px;
  z-index: 10000;
}

.checkedRadio {
  box-shadow: 0 0 10px 3px $main-purple !important;
}

input[type="radio"] {
  display: none;
}
</style>
