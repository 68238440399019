<template>
  <v-dialog v-model="dialogoEditar" persistent fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot> </slot>
      </div>
    </template>
    <v-sheet class="dialogoEditar pa-5" light>
      <div class="col-12 col-md-5 ma-auto">
        <h3>{{  lenguages[idioma].misDatos.nombreYApellido || 'Nombre y apellido'}}</h3>
        <v-text-field
          outlined
          dense
          :color="colors.mainPurple"
          placeholder="Ejemplo Pedro Martinez"
          v-model="nombre"
          :rules="rules.name.concat(rules.required)"
          validate-on-blur
        ></v-text-field>

        <h3>
          {{  lenguages[idioma].misDatos.tipoDeDocumento || 'Tipo de documento'}}
        </h3>
        <v-row no-gutters align="baseline">
          <v-col cols="12" md="4">
            <v-select
              dense
              :items="documentos"
              v-model="documento"
              outlined
              :color="colors.mainPurple"
              :item-color="colors.mainPurple"
              :rules="rules.required"
            />
          </v-col>
          <v-col
            v-if="documento != documentos[0].text"
            class="d-md-none"
            cols="12"
          >
            <h3>N° de Documento</h3>
          </v-col>

          <v-col cols="1" class="d-none d-md-block"></v-col>
          <v-col v-if="documento === 'RUT'" cols="5" md="3">
            <v-text-field
              dense
              :color="colors.mainPurple"
              placeholder="12345678"
              v-model="rutSinDigitoVerificador"
              :rules="rules.rut.concat(rules.required)"
              outlined
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col v-if="documento === 'RUT'" class="text-center" cols="1">
            <h2>-</h2>
          </v-col>
          <v-col v-if="documento === 'RUT'" cols="2" md="1">
            <v-text-field
              outlined
              dense
              :color="colors.mainPurple"
              placeholder="0"
              v-model="digitoVerificador"
              :rules="rules.digitoVerificador.concat(rules.required)"
            ></v-text-field>
          </v-col>
          <v-col v-else-if="documento === 'Pasaporte'">
            <v-text-field
              dense
              :color="colors.mainPurple"
              placeholder="ABC12345678"
              v-model="numeroPasaporte"
              :rules="rules.required"
              outlined
              validate-on-blur
              prepend-inner-icon="mdi-passport"
            ></v-text-field>
          </v-col>
        </v-row>

        <h3>{{  lenguages[idioma].misDatos.paisDeOrigen || 'País de Origen'}}</h3>
        <v-select
          dense
          :items="paises"
          v-model="paisDeOrigen"
          outlined
          :color="colors.mainPurple"
          :item-color="colors.mainPurple"
          :rules="rules.required"
        />
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <h3>{{  lenguages[idioma].misDatos.correoElectronico || 'Correo electrónico'}}</h3>
            <v-text-field
              outlined
              dense
              :color="colors.mainPurple"
              placeholder="Dirección de domicilio"
              prepend-inner-icon="mdi-email"
              v-model="email"
              :rules="rules.email.concat(rules.required)"
              validate-on-blur
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <h3>{{  lenguages[idioma].misDatos.numeroMovil }}</h3>
            <v-text-field
              outlined
              dense
              :color="colors.mainPurple"
              placeholder="Tu teléfono móvil"
              v-model="telefono"
              type="number"
              prepend-inner-icon="mdi-phone"
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>

        <h3>{{  lenguages[idioma].misDatos.direccion }}</h3>
        <v-text-field
          outlined
          dense
          :color="colors.mainPurple"
          placeholder="Dirección de domicilio"
          prepend-inner-icon="mdi-map-marker"
          v-model="direccion"
          :rules="rules.required"
        ></v-text-field>

        <h3>{{  lenguages[idioma].misDatos.intereses }}</h3>
        <div class="d-flex flex-wrap">
          <v-checkbox
            class="mr-3"
            v-model="intereses.helicoptero"
            :label="lenguages[idioma].misDatos.helicoptero"
          ></v-checkbox>

          <v-checkbox
            class="mr-3"
            v-model="intereses.avion"
            :label="lenguages[idioma].misDatos.avion"
          ></v-checkbox>
          <v-checkbox
            class="mr-3"
            v-model="intereses.viñas"
            :label="lenguages[idioma].misDatos.vinas"
          ></v-checkbox>
          <v-checkbox
            class="mr-3"
            v-model="intereses.playa"
            :label="lenguages[idioma].misDatos.playa"
          ></v-checkbox>
          <v-checkbox
            class="mr-3"
            v-model="intereses.mar"
            :label="lenguages[idioma].misDatos.mar"
          ></v-checkbox>
          <v-checkbox
            class="mr-3"
            v-model="intereses.montañas"
            :label="lenguages[idioma].misDatos.montanas"
          ></v-checkbox>
          <v-checkbox
            class="mr-3"
            v-model="intereses.deportes"
            :label="lenguages[idioma].misDatos.deportes"
          ></v-checkbox>
          <v-checkbox
            class="mr-3"
            v-model="intereses.aventuras"
            :label="lenguages[idioma].misDatos.aventuras"
          ></v-checkbox>
        </div>
        <!-- confirmar los cambios a los datos del usuario -->
        <v-row>
          <v-col>
            <div @click="cancelar">
              <Boton :texto="lenguages[idioma].misDatos.cancelar" color="red" width="100%" />
            </div>
          </v-col>
          <v-col @click="confirmarEdicion">
            <Boton
              :deshabilitar="!seHanEditadoDatos"
              :texto="lenguages[idioma].misDatos.confirmarEdicion"
              width="100%"
            />
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import worldCountries from "@/helpers/worldCountries.js";
import { mapActions, mapState } from "vuex";
export default {
  name: "EditarMisDatos",
  components: { Boton },
  data() {
    return {
      dialogoEditar: false,
      documentos: [
        { text: "RUT", disabled: false },
        { text: "Pasaporte", disabled: false },
      ],
      paises: [],
      //Datos Usuario
      nombre: "",
      documento: "RUT",
      rutSinDigitoVerificador: "",
      digitoVerificador: "",
      numeroPasaporte: "",
      paisDeOrigen: "Chile",
      email: "",
      telefono: "569",
      direccion: "",
      intereses: {
        helicoptero: false,
        avion: false,
        viñas: false,
        playa: false,
        mar: false,
        montañas: false,
        aventuras: false,
        deportes: false,
      },
    };
  },
  computed: {
    ...mapState("Lenguages", ["lenguages", "idioma"]),
    ...mapState("Auth", ["usuario"]),
    ...mapState(["colors", "icons"]),
    rules() {
      let reglas = {
        name: [
          // (v) => !!v || "Nombre requerido",
          (v) =>
            v.length >= 7 ||
            "El nombre completo del pasajero debe tener al menos 7 caracteres",
        ],
        email: [
          // (v) => !!v || "Correo electrónico requerido",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "El correo electrónico ingresado no es válido",
        ],
        rut: [(v) => (v.length <= 8 && v.length >= 7) || "Rut no válido"],

        digitoVerificador: [
          (v) => v.length === 1 || "Sólo 1 caracter permitido",
        ],
        required: [(v) => !!v || "Campo requerido"],
      };

      return reglas;
    },
    seHanEditadoDatos() {
      if (
        this.usuario &&
        ((this.usuario.displayName != this.nombre &&
          this.nombre.trim() != "") ||
          (this.rutIngresadoEsDistintoAlDelUsuario &&
            (this.rutSinDigitoVerificador != "" ||
              this.digitoVerificador != "")) ||
          (this.usuario.email != this.email && this.email.trim() != "") ||
          (this.usuario.direccion != this.direccion &&
            this.direccion.trim() != "") ||
          (this.usuario.pasaporte != this.numeroPasaporte &&
            this.numeroPasaporte.trim() != "") ||
          (this.usuario.nacionalidad != this.paisDeOrigen &&
            this.paisDeOrigen.trim() != "") ||
          (this.usuario.telefono != this.telefono &&
            this.telefono.trim() != "" &&
            this.telefono.trim() != "569"))
      ) {
        return true;
      } else return false;
    },
    rutIngresadoEsDistintoAlDelUsuario() {
      const rutPerfil = this.usuario.rut;
      const rutIngresado = `${this.rutSinDigitoVerificador.trim()}-${this.digitoVerificador
        .trim()
        .toLocaleLowerCase()}`;

      return rutPerfil != rutIngresado;
    },
  },
  methods: {
    ...mapActions("Auth", ["actualizarDatosUsuario"]),
    autocompletarDatosDeUsuario() {
      const separarRut = this.usuario.rut ? this.usuario.rut.split("-") : "";

      this.nombre = this.usuario.displayName ? this.usuario.displayName : "";
      this.email = this.usuario.email ? this.usuario.email : "";
      this.rutSinDigitoVerificador = separarRut ? separarRut[0] : "";
      this.digitoVerificador = separarRut ? separarRut[1] : "";
      this.pasaporte = this.usuario.pasaporte ? this.usuario.pasaporte : "";
      this.paisDeOrigen = this.usuario.nacionalidad
        ? this.usuario.nacionalidad
        : "Chile";
      this.telefono = this.usuario.telefono ? this.usuario.telefono : "569";
      this.direccion = this.usuario.direccion ? this.usuario.direccion : "";
    },
    async confirmarEdicion() {
      const datosUsuario = {
        nombre: this.nombre.trim(),
        documento: this.documento,
        rut: `${this.rutSinDigitoVerificador.trim()}-${this.digitoVerificador
          .trim()
          .toLocaleLowerCase()}`,
        pasaporte: this.numeroPasaporte,
        pais: this.paisDeOrigen,
        email: this.email.trim(),
        telefono: +this.telefono,
        direccion: this.direccion.trim(),
        intereses: this.intereses,
      };
      await this.actualizarDatosUsuario(datosUsuario);
      this.dialogoEditar = false;
    },
    cancelar() {
      this.dialogoEditar = false;
      this.autocompletarDatosDeUsuario();
    },
  },
  mounted() {
    this.autocompletarDatosDeUsuario();
    this.paises = worldCountries;
  },
};
</script>

<style lang="scss" scoped>
.dialogoEditar {
  background: $main-white;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15) !important;
  border-radius: 20px;
  height: 100%;
  margin-top: 100px;
  z-index: 4;
}
</style>
