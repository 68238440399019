var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.evaluar)?_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-sheet',{staticClass:"mt-2 pa-4 rounded-lg elevation-4",attrs:{"width":"fit-content"}},[_c('h3',[_vm._v(" ¿Qué te pareció tu vuelo a "+_vm._s(_vm.evaluar.destino)+" el "+_vm._s(_vm.fechaConMesEscrito(_vm.evaluar.fecha))+" ? ")]),_c('p',[_vm._v("Cuéntanos como fue tu experiencia de viaje")]),_c('br'),_c('v-btn',{staticClass:"pa-0",style:({
            textTransform: 'initial',
            color: _vm.colors.mainPurple,
            fontWeight: '600',
          }),attrs:{"color":"transparent","elevation":"0"}},[_vm._v("Califica tu vuelo")])],1)],1)]}}],null,false,664127220),model:{value:(_vm.dialogoEvaluiacionOperador),callback:function ($$v) {_vm.dialogoEvaluiacionOperador=$$v},expression:"dialogoEvaluiacionOperador"}},[_c('v-sheet',{staticClass:"evaluacion pa-6"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-rating',{attrs:{"color":"warning","background-color":"#C4C4C4","size":_vm.ratingSize},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}})],1),_c('v-textarea',{attrs:{"outlined":"","dense":"","rows":"3","placeholder":"Cuéntanos tu experiencia de viaje","no-resize":"","color":_vm.colors.mainPurple},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}}),_c('div',{on:{"click":_vm.enviarComentario}},[_c('Boton',{staticClass:"calificarExperiencia__btn",attrs:{"texto":"Enviar calificación","height":"47px","deshabilitar":_vm.rating === 0}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }